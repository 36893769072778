import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { criarLead } from "../services/onboarding";
import { POST_CRIAR_LEAD } from "../query/constants";

function UseNewLead() {
  const navigate = useNavigate();

  const mutationLead = useMutation({
    mutationFn: criarLead,
    mutationKey: [POST_CRIAR_LEAD],
    onSuccess(data) {
      if (data.status === "LEAD_EXISTENTE") {
        throw new Error(data.status);
      }
      if (data.status === "CONTA_EXISTENTE") {
        throw new Error(data.status);
      }
      if (data.status === "INPUT_INVALIDO") {
        throw new Error(data.status);
      }
      navigate("/holder");
    },
    onError(error) {
      console.log(error);
    },
  });
  return mutationLead;
}

export default UseNewLead;
