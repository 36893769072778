import React from "react";
import "./CnpjHolder.css";
interface CardPropsHolderCnpj {
  cnpj: string;
  social: string;
}

const CnpjHolder: React.FC<CardPropsHolderCnpj> = ({ cnpj, social }) => {
  return (
    <section className="section-datas-razao-social" id="template-areas-cnpj">
      <div className="container-label-value-holder-cnpj" id="cnpj-div">
        <h4 className="label-cnpj-social">CNPJ</h4>
        <p className="value-cnpj-social">{cnpj}</p>
      </div>
      <div className="container-label-value-holder-cnpj" id="razao-social-div">
        <h4 className="label-cnpj-social">Razão Social</h4>
        <p className="value-cnpj-social">{social}</p>
      </div>
    </section>
  );
};

export default CnpjHolder;
