import React, { Ref, useImperativeHandle, useState } from "react";
import Modal from "../../components/modal/Modal";
import AlertEmail from "../../assets/contact/alert-email.png";
import ButtonComponent from "../../components/ButtonComponent";
import { ModalRef } from "../../utils/modal";


export const ModalEmailExistente = React.forwardRef((
    {
        handleReproveLead, 
        handleContinueCadastro
    }: {
        handleReproveLead: () => void;
        handleContinueCadastro: () => void
    }, ref: Ref<ModalRef>) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const open = () => {setIsOpen(true)};
    const close = () => {setIsOpen(false)};

    useImperativeHandle(ref, () => ({
        open,
        close
    }))

    return isOpen ? <div className="modal-position">
      <Modal id="modal-erros" onClose={() => close()}>
        <div className="modal-error-header">
          <img width="40px" height="40px" src={AlertEmail} alt="" />
          <h1 className="modal-error-title">E-mail já cadastrado</h1>
          <p className="email-exist-text">
            O e-mail informado já possui um cadastro em andamento. Como
            deseja seguir?
          </p>
        </div>
        <div className="container-button-email-exists">
          <ButtonComponent
            className="btn-voltar-exist"
            onClick={handleReproveLead}
          >
            Iniciar novo cadastro
          </ButtonComponent>

          <ButtonComponent
            className="btn-email-exists"
            onClick={handleContinueCadastro}
          >
            Continuar de onde parei
          </ButtonComponent>
        </div>
      </Modal>
    </div> : <></>
}) 