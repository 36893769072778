import React from "react";
import styles from "./DocumentoInvalidoModal.module.css";

import {
  NewModal,
  NewModalContent,
  NewModalFooter,
  NewModalBody,
  NewModalHeader,
  NewModalRef,
  NewModalTitle,
  NewModalCloseButton,
} from "../NewModal/NewModal";

import icon from "../../../assets/contact/cpfCnpj-alert.png";
import ButtonComponent from "../../ButtonComponent";

type DocumentoInvalidoModalProps = {
  documentType: "CPF" | "CNPJ" | "";
};

const DocumentoInvalidoModal = React.forwardRef<
  NewModalRef,
  DocumentoInvalidoModalProps
>(({ documentType }, ref) => {
  return (
    <NewModal ref={ref}>
      <NewModalContent>
        <NewModalHeader>
          <img width="40px" height="40px" src={icon} alt="" />
          <NewModalTitle> {documentType} Inválido</NewModalTitle>
        </NewModalHeader>

        <NewModalBody>
          <p className={styles.textSize}>
            O {documentType} informado não consta na base de dados da Receita
            Federal.
          </p>
        </NewModalBody>

        <NewModalFooter>
          <NewModalCloseButton>
            <ButtonComponent className={`continue-button ${styles.buttonSize}`}>
              Tentar Novamente
            </ButtonComponent>
          </NewModalCloseButton>
        </NewModalFooter>
      </NewModalContent>
    </NewModal>
  );
});

export default DocumentoInvalidoModal;
