import React, { ReactNode, useRef } from "react";
import "./SecondaryScreen.css";
import { useNavigate } from "react-router-dom";

import logoGud from "../assets/fotos-vivo/logo-gud.svg";
import seloIVI from "../assets/fotos-vivo/selo-Ivi.svg";
import SeloIVI from "./SeloIVI/SeloIVI";

interface SecondaryScreenProps {
  logoSrc: string;
  iconOne: string;
  iconTwo: string;
  children?: ReactNode;
  onclick?: () => void;
}

const SecondaryScreen: React.FC<SecondaryScreenProps> = ({
  logoSrc,
  iconOne,
  children,
}) => {
  const navigate = useNavigate();
  return (
    <div className="my-component">
      <div className="top-section">
        <img
          src={logoGud}
          alt="Logo Gud Energia"
          onClick={() => navigate("/")}
        />
        <img src={logoSrc} alt="Logo-Ivy" onClick={() => navigate("/")} />
      </div>

      <div className="middle-section">{children}</div>

      {/* <img
        src={seloIVI}
        alt="A energia solar por assinatura é oferecida em parceria com a IVI Energia."
        className="secondary-screen-selo-ivi"
      /> */}

      <SeloIVI className="secondary-screen-selo-ivi" />
    </div>
  );
};

export default SecondaryScreen;
