import { useMutation } from "@tanstack/react-query";
import { GET_DISTRIBUIDORAS } from "../query/constants";
import { getDistribuidoras } from "../services/onboarding";
import { useContext } from "react";
import { SessionContext } from "../context/session";

export function useGetDistribuidora() {
  const { setItemSession, getItemSession } = useContext(SessionContext);
  const getDistribuidora = getItemSession("distribuidora") as string;

  const pegarDistribuidora = useMutation({
    mutationFn: getDistribuidoras,
    mutationKey: [GET_DISTRIBUIDORAS],
    onSuccess: (data) => {
      const filterDistribuidora = data.find(
        (item) =>
          item.id === getDistribuidora
      );
      setItemSession(
        "dados-distribuidora",
        JSON.stringify(filterDistribuidora)
      );
    },
  });
  return pegarDistribuidora;
}
