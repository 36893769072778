import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrowRight from "../../assets/arrow-rigth.svg";
import "./RegistrationCompleted.css";
import ButtonComponent from "../../components/ButtonComponent";
import CardContainer from "../../components/card/cardFinish/CardContainer";
import Card from "../../components/card/cardFinish/Card";
import { removeItemStorage } from "../../utils/localStorage";
import { SessionContext } from "../../context/session";
import { textsPath } from "../../services/texts";

import logoVivoAuren from "../../assets/fotos-vivo/logo-vivo-auren.svg";
import logoGud from "../../assets/fotos-vivo/logo-gud.svg";
import thunderIcon from "../../assets/fotos-vivo/thunder-icon.svg";

import savingsIcon from "../../assets/fotos-vivo/savings-icon.svg";
import registeredDocumentIcon from "../../assets/fotos-vivo/registered-document-icon.svg";
import solarPanelIcon from "../../assets/fotos-vivo/solar-panel-icon.svg";
import SeloIVI from "../../components/SeloIVI/SeloIVI";

interface MyComponentProps {}

const RegistrationCompleted: React.FC<MyComponentProps> = () => {
  const { clearStorage } = useContext(SessionContext);
  const navigate = useNavigate();

  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;

  useEffect(() => {
    removeItemStorage("cnpj");
    removeItemStorage("cpf");
    removeItemStorage("holderData");
    removeItemStorage("holderCNPJ");
    removeItemStorage("contact");
    removeItemStorage("arquivo");
    removeItemStorage("distribuidora");
    sessionStorage.clear();
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const handleButtonClick = () => {
    clearStorage();
    navigate("/");
  };
  return (
    <div className="registration-completed-container">
      <header className="registration-completed-header">
        <img src={logoGud} alt="Logo Gud Energia" />
        <img
          src={logoVivoAuren}
          alt="Logo Vivo Auren"
          className="logo-vivo-auren"
        />
      </header>

      <main className="middle-content-registration-complet">
        <img
          src={thunderIcon}
          alt="Icone de raio"
          className="thunder-icon"
          style={{ cursor: "pointer" }}
          onClick={() => (window.location.href = "https://google.com")}
        />
        <h1 className="title-registration-complet">{title}</h1>
        <div className="description-registration-complet">{description}</div>

        <CardContainer>
          <Card
            imgSrc={registeredDocumentIcon}
            altText="Imagem aguardando validação"
            cardText="Aguardando <br /> validação de cadastro"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={solarPanelIcon}
            altText="imagem de painel solar"
            cardText=" Aguardando <br /> benefícios"
            customClass="opacity"
          />
          <img src={arrowRight} className="arrow" alt="Seta para a direita" />
          <Card
            imgSrc={savingsIcon}
            altText="Imagem porco cofre"
            cardText="Economizando <br /> com a IVI"
            customClass="opacity"
          />
        </CardContainer>


        <SeloIVI className="registration-completed-status-selo-ivi" />
        <ButtonComponent
          className="continue-button"
          onClick={handleButtonClick}
          style={{ width: "217px", margin: "20px 0" }}
        >
          Entendi
        </ButtonComponent>
      </main>

    </div>
  );
};

export default RegistrationCompleted;
