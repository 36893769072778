import React from 'react';
import Select, { StylesConfig } from 'react-select';

interface CustomSelectComponentProps {
  options: any[];
  placeholder?: string;
  onSelect?: (selectedOption: any) => void;
  customMarginBottom?: string;
  customWidth?: string;
  customFontSize?: string;
}

const CustomSelectComponent: React.FC<CustomSelectComponentProps> = ({
  options,
  placeholder,
  onSelect,
  customMarginBottom,
  customWidth,
  customFontSize = '16px'
}) => {
  const customStyles: StylesConfig = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderRadius: '10px',
      height: '56px',
      fontSize: customFontSize,
      borderColor: 'transparent',
      backgroundColor: 'white', // define o fundo como branco
      width: customWidth || '245px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Adicione uma sombra sutil
      fontFamily: "'Kumbh Sans', sans-serif",
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      color: '#000000', // Cor preta para o placeholder
    }),
    indicatorSeparator: () => ({
      display: 'none', // remova a linha de separação entre o ícone de clique e o texto
    }),
  };


  const handleSelectChange = (selectedOption: any) => {
    if (onSelect) {
      onSelect(selectedOption);
    }
  };

  return (
    <div style={{ marginBottom: customMarginBottom || '0px', width: customWidth }}>
      <Select
        options={options}
        placeholder={placeholder || 'Selecione a sua distribuidora'}
        styles={customStyles}
        onChange={handleSelectChange}
      />
    </div>
  );
};

export default CustomSelectComponent;
