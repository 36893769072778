import React, { ReactNode } from "react";
import "./ButtonComponent.css"; // Importe seu arquivo de estilo CSS

interface ButtonComponentProps {
  children: ReactNode;
  onClick?: () => any;
  className?: "continue-button" | "back-button" | string;
  // className?: string;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  children,
  onClick,
  className,
  isDisabled = false,
  style,
}) => {
  const buttonClassName = `${className} ${isDisabled ? "disabled" : ""}`;

  return (
    <button
      className={buttonClassName}
      style={style}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default ButtonComponent;
