export const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidName = (name: string): boolean => {
  const nameParts = name.split(" ");

  const isThereTwoWordsWithAtLeastTwoCharacters = nameParts.filter((part) => part.length >= 2).length >= 2;
  const isThereTwoWords = nameParts.length >= 2;
  
  return isThereTwoWords && isThereTwoWordsWithAtLeastTwoCharacters;
};
