import { ItemProps } from "../../CardInformation";
import "./InstallationData.css";

interface CardPropsHolder {
  distributor: string;
  numberInstallation: string;
  address: string;
}
// interface CardPropsHolder {
//   data: { label: string; value: string }[];
// }
const formatedName = (item: ItemProps) => {
  const labels = ["Endereço", "Distribuidora", "Número"];
  if (labels.includes(item.label)) {
    if (item.value.length > 15) {
      return true;
    }
  }
  return false;
};

const InstallationData: React.FC<CardPropsHolder> = ({
  distributor,
  numberInstallation,
  address,
}) => {
  // console.log(data);
  return (
    <section className="section-datas-installation" id="template-areas">
      <div id="distributor-instalation-div">
        <h4 className="label-data-installation">Distribuidora</h4>
        <p className="value-installation">{distributor}</p>
      </div>
      <div id="number-instalation-div">
        <h4 className="label-data-installation">Nº Instalação</h4>
        <p className="value-installation">{numberInstallation}</p>
      </div>
      <div id="address-instalation-div">
        <h4 className="label-data-installation">Endereço</h4>
        <p className="value-installation">{address}</p>
      </div>
    </section>
  );
};

export default InstallationData;
