import React from "react";
import "./CardSimples.css";

interface CardSimpleProps {
  id?: string;
  // text: string;
  width?: string; // Propriedade opcional para largura personalizada
}

const CardSimple: React.FC<CardSimpleProps> = ({ id }) => {
  return (
    <div id={id} className="container-term">
      {/* // <div className="card-simple"> */}
      {/* <div className="container-termo-adesao">
        <h1 className="title-termo-adesao">{text}</h1>
      </div> */}

      {/* </div> */}
    </div>
  );
};

export default CardSimple;
