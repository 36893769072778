import React, { ReactNode, useState, createContext, useEffect } from 'react'

interface AuthenticatedContextType {
  isAuthenticated : boolean;

}

const initialAuthenticatedContext: AuthenticatedContextType = {
  isAuthenticated: false,
 
};

export const AuthenticatedContext = createContext<AuthenticatedContextType>(
  initialAuthenticatedContext
);

interface AuthenticatedProps {
  children: ReactNode;
}


export const AuthenticatedStorage = ({children}: AuthenticatedProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    
    if (token) {
      setIsAuthenticated(true)
    } else {setIsAuthenticated(false)}
  }, [token])
  

  return (
    <AuthenticatedContext.Provider value={{ isAuthenticated}}>
      {children}
    </AuthenticatedContext.Provider>
  );
};
