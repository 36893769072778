import React, { useRef } from "react";
import "./LeftHomeComponent.css";

import logoGud from "../../assets/fotos-vivo/logo-gud.svg";
import logoVivoAuren from "../../assets/fotos-vivo/logo-vivo-auren.svg";

import { ModalTermosDeUso } from "../modal/ModalTermsUso/ModelTermosDeUso";
import { ModalRef } from "../../utils/modal";
import { navigateToLp } from "../../utils/landingPage";
import SeloIVI from "../SeloIVI/SeloIVI";
import Calculator from "../calculator/calculator";

interface LeftHomeProps {
  logoSrc: string;
  title: string;
  description: string;
  imgSrc?: string;
  showCalculator?: boolean;
}

const LeftHomeComponent: React.FC<LeftHomeProps> = ({
  logoSrc,
  title,
  description,
  imgSrc,
  showCalculator,
}) => {
  const modalTermosDeUsoRef = useRef<ModalRef | null>(null);
  return (
    <div className="left-home">
      <div className="container-logo-title-description-left-home">
        <div className="left-home-header">
          <img src={logoGud} alt="logo-gud" className="logo-gud" />
          <img
            src={logoVivoAuren}
            alt="Logo Vivo e Auren"
            className="logo-vivo-auren"
          />
        </div>
      </div>

      <div className="left-home-content">
        <h3 className="left-home-content-title">{title}</h3>
        <span className="left-home-content-description">
          {description.split("\n").map((d) => (
            <p key={d}>{d}</p>
          ))}
        </span>

        {imgSrc ? (
          <img
            src={imgSrc}
            alt="img-esquerda"
            className="left-home-content-icon"
          />
        ) : (
          <></>
        )}
        {showCalculator && (
          <div className="left-home-calculator-container">
            <Calculator />
          </div>
        )}
      </div>

      <ModalTermosDeUso ref={modalTermosDeUsoRef} />

      <div className="left-home-footer">
        <SeloIVI />

        <p
          className="termo-adesao"
          onClick={() => modalTermosDeUsoRef.current?.open()}
        >
          Termos de Uso e Polticia de Privacidade
        </p>
      </div>
    </div>
  );
};

export default LeftHomeComponent;
