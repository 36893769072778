import React from "react";
import "./CardInformation.css"; // Certifique-se de importar ou adicionar os estilos necessários

export interface ItemProps {
  label: string;
  value: string;
}

interface CardInformationProps {
  discount: string;
  consumoMensal: string;
  economiaAnual: string;
}

const CardInformation = ({
  discount,
  consumoMensal,
  economiaAnual,
}: CardInformationProps) => {
  return (
    <section className="section-container-card-information">
      <section className="card-information">
        <div className="data-container" id="template-areas">
          <div id="discount-div">
            <h4 className="label">Você economizará</h4>
            <p className="discount">
              {discount}
              <b className="porcentagem">%</b>
            </p>
          </div>

          <div id="economy-div">
            <h4 className="label">Economia anual*</h4>
            <b className="value-bold">{economiaAnual}</b>
          </div>
          <div id="consumo-div">
            <h4 className="label">Estimativa do consumo mensal</h4>
            <b className="value-bold">{consumoMensal}</b>
          </div>
        </div>
      </section>
      <div className="container-asterisco">
        <p className="asterisco-info-card-info">
          *A economia anual é calculada de acordo com a média de consumo de
          energia presente na fatura fornecida.
        </p>
      </div>
    </section>
  );
};

export default CardInformation;