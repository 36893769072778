import { useEffect, useRef, useState } from "react";

export const TokenInput = ({ setIsButtonEnabled, setErrorMgs, setCode }: 
    {
        setIsButtonEnabled: (isButtonEnabled: boolean) => void;
        setErrorMgs: (errorMsg: string) => void;
        setCode: (code: string) => void;
    }
) => {
    const [passValidation, setPassValidation] = useState({
      numberOne: "",
      numberTwo: "",
      numberThree: "",
      numberFour: "",
      numberFive: "",
      numberSix: "",
    });
    const {
      numberOne,
      numberTwo,
      numberThree,
      numberFour,
      numberFive,
      numberSix,
    } = passValidation;
    

  const numberOneRef = useRef<HTMLInputElement>(null);
  const numberTwoRef = useRef<HTMLInputElement>(null);
  const numberThreeRef = useRef<HTMLInputElement>(null);
  const numberFourRef = useRef<HTMLInputElement>(null);
  const numberFiveRef = useRef<HTMLInputElement>(null);
  const numberSixRef = useRef<HTMLInputElement>(null);
  const code = `${numberOne}${numberTwo}${numberThree}${numberFour}${numberFive}${numberSix}`;

  const refs = [
    numberOneRef,
    numberTwoRef,
    numberThreeRef,
    numberFourRef,
    numberFiveRef,
    numberSixRef,
  ];

  useEffect(() => {
    setCode(`${
        passValidation.numberOne
    }${
        passValidation.numberTwo
    }${
        passValidation.numberThree
    }${
        passValidation.numberFour
    }${
        passValidation.numberFive
    }${
        passValidation.numberSix
    }`)
  }, [passValidation, setCode])

  const handleTabKey = (
    event: React.KeyboardEvent<HTMLInputElement>,
    currentRef: React.RefObject<HTMLInputElement>,
    prevRef: React.RefObject<HTMLInputElement> | null,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (event.key === "Backspace" && code.length <= 6) {
        setIsButtonEnabled(false);
      }
      if (event.currentTarget.value === "" && event.key === "Backspace") {
        setIsButtonEnabled(false);
        event.preventDefault();
        if (prevRef && prevRef.current) {
          prevRef.current.focus();
          prevRef.current.setSelectionRange(1, 1);
        }
      }
    } else if (event.key === "ArrowLeft") {
      event.preventDefault();
      if (prevRef && prevRef.current) {
        prevRef.current.focus();
        prevRef.current.setSelectionRange(1, 1);
      }
    } else if (event.key === "ArrowRight") {
      event.preventDefault();
      if (nextRef.current) {
        nextRef.current.focus();
        nextRef.current.setSelectionRange(0, 0);
      }
    } else if (event.key === "Tab") {
      event.preventDefault();
      if (event.shiftKey) {
        if (prevRef && prevRef.current) {
          prevRef.current.focus();
          prevRef.current.setSelectionRange(0, 0);
        }
      } else {
        if (nextRef.current) {
          nextRef.current.focus();
          nextRef.current.setSelectionRange(0, 0);
        }
      }
    }
  };

  const handleNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    const value = event.target.value.slice(0, 1);
    event.target.value = value;

    if (value !== "") {
      navigateToNextInput(nextRef);
    }
    if (code.length === 5) {
      setIsButtonEnabled(true);
      setErrorMgs("");
    }

    setPassValidation({
      ...passValidation,
      [event.target.name]: value.toUpperCase(),
    });
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLInputElement>,
    index: number
  ) => {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData("text/plain");

    if (pastedData) {
      const pastedValues = pastedData.slice(0, 6).split("");
      for (let i = 0; i < pastedValues.length; i++) {
        setPassValidation({
          numberOne: pastedValues[0],
          numberTwo: pastedValues[1],
          numberThree: pastedValues[2],
          numberFour: pastedValues[3],
          numberFive: pastedValues[4],
          numberSix: pastedValues[5],
        });

        const currentValue = pastedValues[i];
        if (currentValue !== undefined && refs[index + i]) {
          const currentRef = refs[index + i].current;
          if (currentRef) {
            currentRef.value = currentValue;
            const nextRef = refs[index + i + 1]?.current;
            if (nextRef) {
              nextRef.focus();
            }
          }
        }
      }
      setIsButtonEnabled(true);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    prevRef: React.RefObject<HTMLInputElement> | null,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    if (event.key === "Backspace") {
      if (event.currentTarget.value === "") {
        event.preventDefault();
        /* if (prevRef && prevRef.current) {
          prevRef.current.focus() //define o focus para o elemento anterior;
          prevRef.current.setSelectionRange(1, 1, "forward") posiciona o cursor no final do elemento anterior;
        } */
      }
    }
  };
    

  const navigateToNextInput = (nextRef: React.RefObject<HTMLInputElement>) => {
    if (nextRef.current) {
      nextRef.current.focus();
      nextRef.current.setSelectionRange(1, 1);
    }
  };
    
    
    
    
    return <div className="inputs-insider-container">
    <input
      type="text"
      value={passValidation.numberOne}
      name="numberOne"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberTwoRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberOneRef, null, numberTwoRef)
      }
      onKeyUp={(e) => handleKeyDown(e, null, numberTwoRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberOneRef}
    />
    <input
      type="text"
      value={passValidation.numberTwo}
      name="numberTwo"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberThreeRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberTwoRef, numberOneRef, numberThreeRef)
      }
      onKeyUp={(e) => handleKeyDown(e, numberOneRef, numberThreeRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberTwoRef}
    />
    <input
      type="text"
      value={passValidation.numberThree}
      name="numberThree"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberFourRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberThreeRef, numberTwoRef, numberFourRef)
      }
      onKeyUp={(e) => handleKeyDown(e, numberTwoRef, numberFourRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberThreeRef}
    />
    <input
      type="text"
      value={passValidation.numberFour}
      name="numberFour"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberFiveRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberFourRef, numberThreeRef, numberFiveRef)
      }
      onKeyUp={(e) => handleKeyDown(e, numberThreeRef, numberFiveRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberFourRef}
    />
    <input
      type="text"
      value={passValidation.numberFive}
      name="numberFive"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberSixRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberFiveRef, numberFourRef, numberSixRef)
      }
      onKeyUp={(e) => handleKeyDown(e, numberFourRef, numberSixRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberFiveRef}
    />
    <input
      type="text"
      value={passValidation.numberSix}
      name="numberSix"
      className="custom-input-big"
      maxLength={1}
      onChange={(e) => handleNumberChange(e, numberSixRef)}
      onKeyDown={(e) =>
        handleTabKey(e, numberSixRef, numberFiveRef, numberFourRef)
      }
      onKeyUp={(e) => handleKeyDown(e, numberFiveRef, numberFourRef)}
      onPaste={(e) => handlePaste(e, 0)}
      ref={numberSixRef}
    />
  </div>
}