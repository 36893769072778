import { useMutation } from '@tanstack/react-query';
import { initAuth } from '../services/authentication';
import { useNavigate } from 'react-router-dom';

export function useInitAuth() {
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: initAuth,
    mutationKey: ['post-init-auth'],
    onSuccess(data, email) {
      if (data.status !== 'SUCESSO') {
        throw new Error(data.status);
      }
      navigate('/validation');
    },
  });
  return mutation;
}
