import { Input } from "@nextui-org/react"
import Modal from "../../components/modal/Modal"
import ButtonComponent from "../../components/ButtonComponent"
import React, { Ref, useContext, useImperativeHandle, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getLead } from "../../services/onboarding";
import { GET_STATUS_LEAD } from "../../query/constants";
import { useInitAuth } from "../../hooks/useInitAuth";
import { RedirectContext } from "../../context/redirect";
import { AxiosError } from "axios";
import { SessionContext } from "../../context/session";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
  
export type JaInicieiMeuCadastroModalRef = {
    open: (email?: string) => void;
    close: () => void;
}
export const JaInicieiCadastroModal = React.forwardRef(({ setIsLeadInValidacaoFatura }: {
    setIsLeadInValidacaoFatura: (isLeadInValidacaoFatura: boolean) => void;
}, ref: Ref<JaInicieiMeuCadastroModalRef>) => {
    const [email, setEmail] = useState({
        emailValue: "",
        isEmailInvalid: false,
        errorMgs: "",
    });
    const [isOpen, setIsOpen] = useState(false);
    const initAuthHome = useInitAuth();
    const { setContinueResgistration } = useContext(RedirectContext);
    const { setItemSession } = useContext(SessionContext);

    useImperativeHandle(ref, () => ({
        open,
        close,
    }))

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = event.target.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (newEmail === "") {
            return setEmail({
                emailValue: newEmail,
                isEmailInvalid: false,
                errorMgs: "",
            });
        }
    
        if (emailRegex.test(newEmail)) {
            return setEmail({
                emailValue: newEmail,
                isEmailInvalid: false,
                errorMgs: "",
            });
        } else {
            return setEmail({
                emailValue: newEmail,
                isEmailInvalid: true,
                errorMgs: "Por favor, preencha com um e-mail válido",
            });
        }
    };

    const handleButtonClick = () => {
        if (email.emailValue && !email.isEmailInvalid) {
            existLead.mutate(email.emailValue);
        }
    };

    const existLead = useMutation({
      mutationFn: getLead,
      mutationKey: [GET_STATUS_LEAD],
      onSuccess: (data) => {
        const statusLead = data.status;
  
        if (statusLead === "VALIDACAO_FATURA") {
          // VALIDACAO_FATURA
          return setIsLeadInValidacaoFatura(true);
        }
        setItemSession("emailContinue", email.emailValue);
        setContinueResgistration(true);
        initAuthHome.mutate({email: email.emailValue});
      },
      onError(erro: AxiosError) {
        const message = erro.response?.data as string;
        if (message === "LEAD PENDENTE NOT_FOUND") {
          setEmail({
            emailValue: "",
            isEmailInvalid: true,
            errorMgs: "Cadastro não encontrado",
          });
        }
        const stausError = message.split(":")[1].trim();
        if (stausError === "VALIDACAO_FATURA") {
          return setIsLeadInValidacaoFatura(true);
        }
      },
    });

    const open = (email?: string) => {
      if (email) setEmail({ emailValue: email, errorMgs: "", isEmailInvalid: false });
      setIsOpen(true)
    };
    const close = () => {setIsOpen(false)};



    return isOpen ? <div className="modal-position">
      <Modal
        onClose={() => close()}
        className="modal-children-cadastro"
        classNameModal="modal-email-cadastro"
      >
        <div className="content-centered-modal-cadastro">
          <div>
            <h1 className="title-modal-cadastro">Vamos lá</h1>
            <p className="subtitle-modal-cadastro">
              Digite o e-mail de cadastro:
            </p>
          </div>

          <div className="container-input">
            <Input
              type="email"
              label="E-mail"
              value={email.emailValue}
              isInvalid={email.isEmailInvalid}
              onChange={handleEmailChange}
              classNames={{
                inputWrapper: [
                  "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
                ],
              }}
            />
          </div>
          {email.isEmailInvalid && (
            <p className="error-mgs-email-modal-cadastro">
              {email.errorMgs}
            </p>
          )}
        </div>
        <div className="button-modal-container-email">
          <ButtonComponent
            className="back-button"
            // className="btn-voltar"
            onClick={() => close()}
          >
            Fechar
          </ButtonComponent>
          <ButtonComponent
            className="continue-button"
            // className="btn-contact"
            onClick={handleButtonClick}
            isDisabled={
              !email.isEmailInvalid && email.emailValue ? false : true
            }
          >
            Continuar
          </ButtonComponent>
        </div>
        {(initAuthHome.isPending || existLead.isPending) && (
          <LoadingFullPage />
        )}
      </Modal>
    </div> : <></>
});