import { decryptData, encryptData } from "./criptografia";

export async function getItemStorage(key: string): Promise<string | null> {
  const encryptedValue = sessionStorage.getItem(key);
  if (!encryptedValue) {
    return null;
  }

  try {
    const decryptedValue = await decryptData(encryptedValue);
    return decryptedValue;
  } catch (error) {
    console.error("Erro ao descriptografar os dados:", error);
    return null;
  }
}

export function setItemStorage(key: string, value: string): void {
  if (!key) throw new Error("Chave ou valor inválido(s).");
  if (!value) {
    removeItemStorage(key);
    return;
  }

  encryptData(value)
    .then((encryptedValue) => {
      sessionStorage.setItem(key, encryptedValue);
    })
    .catch((error) => {
      console.error("Erro ao criptografar os dados:", error);
    });
}
export function removeItemStorage(key: string) {
  return sessionStorage.removeItem(key);
}

// usado para edição dos dados

export function getLocalStorage(key: string): string | null {
  return sessionStorage.getItem(key);
}

export function setItemLocalStorage(key: string, value: string): void {
  sessionStorage.setItem(key, value);
}
export function removeLocalStorage(key: string) {
  return sessionStorage.removeItem(key);
}
