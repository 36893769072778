

import ButtonComponent from '../../ButtonComponent'
import Modal from '../Modal'
import CpfCnpjIconAlert from '../../../assets/contact/cpfCnpj-alert.png'
import './ErrorModal.css'
import React, { Ref, useImperativeHandle, useState } from 'react'

type ErrorProps = {
  message?: string;
  errorItems?: string[];
  title: string;
}

export type ErrorModalRef = {
  showError: (errorProps: ErrorProps) => void;
  close: () => void;
}

const ErrorModal = React.forwardRef((_, ref: Ref<ErrorModalRef>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorProps, setErrorProps] = useState<ErrorProps>({title: ""});

  useImperativeHandle(ref, () => ({
    showError,
    close,
  }))

  const showError = (errorProps: ErrorProps) => {setIsOpen(true); setErrorProps(errorProps)};
  const close = () => {setIsOpen(false)};
  const { title, message, errorItems } = errorProps;

  return (
    isOpen ?
    <div className="modal-position">
      <Modal id="modal-erro" onClose={() => close()}>
        <div className="modal-error-header">
          <img width="40px" height="40px" src={CpfCnpjIconAlert} alt="" />
          <h1 className="modal-error-title">
            {title}
          </h1>
          <p className="modal-error-text">
            {message}

            {
              errorItems ? 
              <ul style={{listStyle: "disc"}}>
              {
                errorItems.map(ei => <li>
                    {ei}
                  </li>
                ) 
              } 
              </ul>
               : null}
          </p>
        </div>
        <div className="container-button-email-exists">
          <ButtonComponent
            className="btn-email-exists"
            onClick={() => close()}
          >
            OK
          </ButtonComponent>
        </div>
      </Modal>
    </div>: <></>
  ) 
})

export default ErrorModal
