import { api } from "../lib/axios/config";

export async function validateManual(form: FormData) {
  const response = await api.post(
    `onboarding/request-manual-validation`,
    form,
    {
      headers: {
        "Content-Type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
    }
  );
  return response.data;
}
