import { Accordion, AccordionItem } from "@nextui-org/react";
import ModalFull from "./modal/ModalFull"
import ArrowDown from "../assets/home/arrow-down";
import ButtonComponent from "./ButtonComponent";
import Faq from "../utils/faq.json";
import React, { Ref, useImperativeHandle, useState } from "react";

export type FaqModalRef = {
    open: () => void;
    close: () => void;
}

export const FaqModal = React.forwardRef((_, ref: Ref<FaqModalRef>) => {
    const [isOpen, setIsOpen] = useState(false);

    const open = () => {setIsOpen(true)};
    const close = () => {setIsOpen(false)};

    useImperativeHandle(ref, () => ({
        open,
        close
    }))

    return isOpen ? (
      <ModalFull onClose={close}>
        <div className="modal-content-component">
          <div className="text-modal-full">
            <h1>Perguntas frequentes</h1>
          </div>
          <div className="content-card-boby">
            <Accordion variant="light">
              {Faq.map((itemAccordion) => (
                <AccordionItem
                  key={itemAccordion.key}
                  className="animation-body"
                  aria-label={itemAccordion["aria-label"]}
                  title={itemAccordion.title}
                  indicator={<ArrowDown />}
                >
                  {itemAccordion.defaultContent}{" "}
                  {/* Substitua defaultContent pelo conteúdo específico para esta pergunta */}
                </AccordionItem>
              ))}
            </Accordion>
          </div>

          <div className="centered-content">
            <ButtonComponent
              className="btn-contact btn-modal-full"
              onClick={close}
            >
              Fechar
            </ButtonComponent>
          </div>
        </div>
      </ModalFull>
    ) : <></>
});