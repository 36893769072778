import React, { ReactNode, createContext, useState } from "react";

interface EditRedirectContextType {
  pathEdit: string;
  continueResgistration: boolean;
  setContinueResgistration: React.Dispatch<React.SetStateAction<boolean>>
  setPathEdit: React.Dispatch<React.SetStateAction<string>>;
}

const initialEditRedirectContext: EditRedirectContextType = {
  pathEdit: "",
  continueResgistration: false,
  setPathEdit: () => {},
  setContinueResgistration: () => {},
};

export const RedirectContext = createContext<EditRedirectContextType>(
  initialEditRedirectContext
);

interface EditRedirectStorageProps {
  children: ReactNode;
}

export const RedirectStorage = ({children}: EditRedirectStorageProps) => {
  const [pathEdit, setPathEdit] = useState("");
  const [continueResgistration, setContinueResgistration] = useState<boolean>(false)

  return (
    <RedirectContext.Provider value={{ pathEdit, setPathEdit, continueResgistration,  setContinueResgistration}}>
      {children}
    </RedirectContext.Provider>
  );
};
