import React, { Ref, useImperativeHandle, useState } from "react";
import ButtonComponent from "../../ButtonComponent"
import ModalFull from "../ModalFull"
import { TermosDeUso } from "./TermosDeUso"
import { ModalRef } from "../../../utils/modal";

export const ModalTermosDeUso = React.forwardRef((_, ref: Ref<ModalRef>) => {
    
    const [isOpen, setIsOpen] = useState(false);
    const open = () => {setIsOpen(true)};
    const close = () => {setIsOpen(false)};

    useImperativeHandle(ref, () => ({
        open,
        close
    }))
    return isOpen ? <ModalFull
    onClose={() => close()}
    style={{
      height: "70vh",
      width: "60vw",
      padding: "10px 30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div className="content-modal-terms">
      <div>
        <TermosDeUso />
      </div>
    </div>
    <div className="button-modal-container-terms">
      <ButtonComponent
        className="back-button"
        onClick={() => close()}
      >
        Fechar
      </ButtonComponent>
    </div>
  </ModalFull> : <></>
});