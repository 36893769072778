export const valorFornecimento = (fornecimento: string): number => {
  let fornecimentoValue: number = 0;
  const lowerCaseFornecimento = fornecimento.toLowerCase();

  if (lowerCaseFornecimento.includes("monofásico")) {
    fornecimentoValue = 30;
  } else if (lowerCaseFornecimento.includes("bifásico")) {
    fornecimentoValue = 50;
  } else if (lowerCaseFornecimento.includes("trifásico")) {
    fornecimentoValue = 100;
  }

  return fornecimentoValue;
};
