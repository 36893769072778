import { Input } from "@nextui-org/react";
import { useContext, useEffect, useState } from "react";
import { isValidEmail, isValidName } from "../../services/validations";
import { SessionContext } from "../../context/session";
import { ACCEPTED_AREA_CODES } from "../../utils/phoneUtils";

function checkAreaCode(phoneNumber: string) {
  const areaCode = phoneNumber.replace(/\D/g, "").slice(0, 2);
  return ACCEPTED_AREA_CODES.includes(areaCode);
}

export const ContactForm = ({
  noEditEmail,
  continueEmail,
  setFormData,
  setFormValidation,
}: {
  noEditEmail: boolean;
  continueEmail: boolean;
  setFormData: (formData: any) => void;
  setFormValidation: (formValid: boolean) => void;
}) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [isFieldInvalid, setIsFieldInvalid] = useState({
    isNameInvalid: false,
    isEmailInvalid: false,
    isPhoneInvalid: false,
  });
  const [formValid, setFormValid] = useState({
    nameValidad: false,
    emailValidad: false,
    phoneValidad: false,
  });
  const { getItemSession, setItemSession } = useContext(SessionContext);
  const phoneRegex = /^\([0-9]{2}\) [0-9]?[0-9]{4}-[0-9]{4}$/;
  const { isNameInvalid, isEmailInvalid, isPhoneInvalid } = isFieldInvalid;

  const [erroMgs, setErroMgs] = useState({
    email: "",
    phone: "",
  });

  useEffect(() => {
    const storageContact = getItemSession("contact");
    if (storageContact != null) {
      const contactModifyStorage = JSON.parse(storageContact);
      setName(contactModifyStorage.name);
      setEmail(contactModifyStorage.email);
      setPhone(contactModifyStorage.phone);
      setFormValid({
        nameValidad: true,
        emailValidad: true,
        phoneValidad: true,
      });
      setFormValidation(true);
      setFormData({
        name: contactModifyStorage.name,
        email: contactModifyStorage.email,
        phone: contactModifyStorage.phone,
      });
    }
  }, []);

  useEffect(() => {
    const { nameValidad, emailValidad, phoneValidad } = formValid;
    setFormValidation(nameValidad && emailValidad && phoneValidad);
    setFormData({
      name,
      email,
      phone,
    });
  }, [name, email, phone, formValid]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const regexNumber = /[0-9]/;
    if (regexNumber.test(newName)) return;

    validateName(newName);
  };

  const validateName = (newName: string) => {
    setName(newName);
    setIsFieldInvalid({
      ...isFieldInvalid,
      isNameInvalid: !isValidName(newName),
    });
    setFormValid({ ...formValid, nameValidad: isValidName(newName) });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    validateEmail(newEmail);
  };

  const validateEmail = (newEmail: string) => {
    setEmail(newEmail);
    setFormValid({ ...formValid, emailValidad: isValidEmail(newEmail) });
    setIsFieldInvalid({
      ...isFieldInvalid,
      isEmailInvalid: !isValidEmail(newEmail),
    });

    if (!isValidEmail(newEmail))
      setErroMgs({
        ...erroMgs,
        email: "Por favor, preencha com um e-mail válido",
      });
  };

  function handleWhats(e: any) {
    let phone = getDigits(e.target.value);
    if (phone.length > 11) phone = phone.slice(0, 11);

    handlePhoneChange(phoneMask(phone));

    setPhone(phoneMask(phone));
  }

  const hasRepeatedDigits = (phoneNumber: string) => {
    const digits = phoneNumber.match(/\d/g);
    if (!digits) return false;
    const digitCounts: { [key: string]: number } = {};
    for (const digit of digits) {
      digitCounts[digit] = (digitCounts[digit] || 0) + 1;
      if (digitCounts[digit] >= 8) return true;
    }
    return false;
  };

  const handlePhoneChange = (event: string) => {
    const newPhone = event;
    const isPhoneInvalid =
      hasRepeatedDigits(newPhone) || !phoneRegex.test(newPhone);
    const isAreaCodeInvalid = !checkAreaCode(newPhone);

    function phoneErrorMessages() {
      if (isAreaCodeInvalid) return "Código de área inválido.";
      if (isPhoneInvalid) return "Por favor, preencha com um número existente.";
      return "";
    }

    setIsFieldInvalid({
      ...isFieldInvalid,
      isPhoneInvalid: isPhoneInvalid || isAreaCodeInvalid,
    });
    setFormValid({ ...formValid, phoneValidad: !isPhoneInvalid });
    setErroMgs({
      ...erroMgs,
      phone: phoneErrorMessages(),
    });
  };

  const phoneMask = (value: any) => {
    if (!value) return "";
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d{2})(\d)/, "($1) $2");
    value = value.replace(/(\d)(\d{4})$/, "$1-$2");
    return value;
  };

  const getDigits = (value: string) => value.replace(/[^\d]/g, "");

  return (
    <>
      {/* nome */}
      <div className="container-inputs-contact">
        <Input
          type="text"
          size="md"
          label="Nome"
          value={name}
          onChange={handleNameChange}
          isInvalid={isNameInvalid}
          color="primary"
          classNames={{
            input: ["text-kumbh-sans"],
            label: ["font-kumbh-sans"],
            inputWrapper: [
              "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
            ],
          }}
        />
      </div>
      {isNameInvalid && (
        <p className="error-mgs-contact">
          Por favor, digite seu nome e sobrenome
        </p>
      )}

      {/* e-mail */}
      <div className="container-inputs-contact">
        <Input
          type="email"
          size="md"
          label="E-mail"
          value={email}
          onChange={handleEmailChange}
          isInvalid={isEmailInvalid}
          color="primary"
          isDisabled={noEditEmail || continueEmail}
          classNames={{
            input: ["text-noto"],
            inputWrapper: [
              "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
            ],
          }}
        />
      </div>
      {isEmailInvalid && <p className="error-mgs-contact">{erroMgs.email}</p>}

      {/* celular */}
      <div className="container-inputs-contact box-whatsApp">
        <Input
          type="text"
          size="md"
          label="Celular (WhatsApp)"
          isInvalid={isPhoneInvalid}
          value={phone}
          onChange={(e: any) => handleWhats(e)}
          color="primary"
          classNames={{
            input: ["text-noto"],
            inputWrapper: [
              "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
            ],
          }}
        />
      </div>

      {isPhoneInvalid && <p className="error-mgs-contact">{erroMgs.phone}</p>}
    </>
  );
};
