import termsUsoTratams from "../../../assets/termosUso/IVI-Politica-de-Privacidade-alt-1.png";

export const TermosDeUso = () => {
  return (
    <div>
      <h1 className="title-container-modal-terms">I – Introdução</h1>
      <p className="paragra-container-modal-terms">
        A IVI Energia S.A., inscrita no CNPJ/ME sob o nº
        50.167.742/0001-84, com sede na Av. das Nações Unidas, n.º 14.261, 25º
        andar, Ala B, Vila Gertrudes, São Paulo/SP, CEP: 04794-000 é a
        Controladora dos seus dados pessoais, na forma da definição prevista na
        Lei Geral de Proteção de Dados Pessoais.
        <br />
        <br />
        Quando você navega em nosso website algumas informações e dados pessoais
        são acreditados à nossa empresa. Desta forma, a IVI Energia elaborou
        esta Política de Privacidade, pois está preocupada em garantir a
        privacidade e proteger os dados dos seus usuários. Aqui, neste
        documento, vamos detalhar como esses dados são utilizados e para quais
        finalidades.
        <br />
        <br />A IVI Energia valoriza a privacidade e a proteção de dados
        pessoais de seus usuários, clientes, colaboradores, parceiros,
        fornecedores e todos os demais titulares de dados pessoais e está
        comprometida em garantir que esses direitos sejam respeitados,
        observando a Lei nº 13.709/2018 — a Lei Geral de Proteção de Dados
        Pessoais (“LGPD”) — e demais leis aplicáveis sobre o tema.
        <br />
        <br />
        Esta Política de Privacidade (“Política”) se aplica a todos os usuários
        e clientes das soluções ofertadas pela IVI Energia e tem como objetivo
        informar como seus dados pessoais serão coletados, processados,
        armazenados, compartilhados e de qualquer outra forma tratados quando
        acessam e utilizam nosso site institucional https://ivienergia.com, de
        acordo com as leis de privacidade e proteção de dados pessoais em vigor.
        <br />
        <br />
        Para melhor entendimento desta Política de Privacidade, seguem as
        seguintes definições:
        <br />
        <br />
        (a) “Dados Pessoais”: qualquer informação obtida em razão do presente
        instrumento, relacionada a pessoa natural identificada ou identificável,
        como por exemplo: nome, CPF, RG, endereço residencial ou comercial,
        número de telefone fixo ou móvel, endereço de e-mail, informações de
        geolocalização, entre outros.
        <br />
        <br />
        (b) “Dados Pessoais Sensíveis”: dado pessoal sobre origem racial ou
        étnica, convicção religiosa, opinião política, filiação a sindicato ou a
        organização de caráter religioso, filosófico ou político, dado referente
        à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado
        a uma pessoa natural;
        <br />
        <br />
        (c) “Titular dos dados”: pessoa natural a quem se referem os dados
        pessoais que são objeto de tratamento.
        <br />
        <br />
        (d) “Tratamento”: qualquer operação ou conjunto de operações efetuadas
        com dados pessoais ou sobre conjuntos de dados pessoais, por meios
        automatizados ou não automatizados, tais como a coleta, o registro, a
        organização, a estruturação, a conservação, a adaptação ou alteração, a
        recuperação, a consulta, a utilização, a divulgação por transmissão,
        difusão ou qualquer outra forma de disponibilização, a comparação ou
        interconexão, a limitação, a eliminação ou a destruição.
        <br />
        <br />
        (e) “LGPD”: É a Lei Geral de Proteção de Dados, Lei nº 13.709/2018.
        <br />
        <br />
        (f) “Usuários”: Qualquer pessoa que contrate e utilize as
        funcionalidades do site da IVI Energia.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        II. Quais dados coletamos?
      </h1>

      <p className="paragra-container-modal-terms">
        O tratamento dos Dados Pessoais está atrelado ao relacionamento do
        titular com a IVI Energia. Os dados utilizados serão aqueles solicitados
        no cadastro do Titular interessado em realização de parceria ou envio de
        currículo do Titular interessado em trabalhar conosco. A IVI Energia, em
        respeito ao princípio da transparência, detalha abaixo como que ocorre
        essa situação:
        <br />
        <br />
      </p>
      <img src={termsUsoTratams} style={{ margin: "0 auto" }} />
      <p className="paragra-container-modal-terms">
        <br />
        Para melhor entender sobre as finalidades e gerenciamento de cookies,
        recomendamos acessar nosso aviso de cookies contido em janela específica
        no nosso website.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        III. Como coletamos seus dados pessoais?
      </h1>

      <p className="paragra-container-modal-terms">
        O acesso normal ao nosso website não exige o fornecimento de dados
        pessoais, contudo, quando você acessa e utiliza nosso website, ou quando
        interage com a IVI em outros meios, podemos coletar seus dados pessoais
        de formas distintas, a exemplo das indicadas abaixo:
        <br />
        <br />
        1. Pelo fornecimento das informações diretamente por você, como, por
        exemplo, quando você preenche voluntariamente seus dados para se
        inscrever no nosso newsletter, para se cadastrar como cliente, para se
        candidatar a vagas disponíveis na área “Trabalhe com a gente”, para
        realizar seu pré-cadastro no “Seja nosso parceiro”, ou quando você entra
        em contato com a IVI pela área “Contato” ou por outros meios ou canais
        de comunicação, como telefone ou mensagens em nossas redes sociais.
        <br />
        <br />
        2. Pela coleta automática de dados por meio de ferramentas do seu
        navegador e de outras tecnologias, como cookies (vide a aviso de
        Cookies).
        <br />
        <br />
        3. Pela recepção de seus dados pessoais de empresas de recrutamento,
        headhunters, indicações, ou outras pessoas autorizadas que compartilhem
        seus dados conosco, especialmente para fins de permitir a sua
        participação em processos seletivos conduzidos pela IVI Energia.
        <br />
        <br />
        4. Pela recepção de seus dados pessoais de nossos parceiros, prestadores
        de serviços, fornecedores ou terceiros, como nos casos em que tenhamos
        relações negociais com o seu empregador, ou quando utilizamos
        plataformas terceiras para seu cadastro, como nos casos do “Trabalhe com
        a gente”, “Cadastre-se” e da área de “Seja nosso parceiro”.
        <br />
        <br />
        5. Pela recepção de seus dados pessoais através da plataforma whatsapp
        business, onde você manifesta o seu consentimento ao receber uma
        permissão de adesão que confirma seu desejo de receber mensagens da IVI
        Energia.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        IV. Para qual finalidade a IVI Energia trata seus Dados Pessoais?
      </h1>
      <p className="paragra-container-modal-terms">
        A IVI Energia realiza o tratamento de seus dados pessoais para diversas
        finalidades, de acordo com o seu relacionamento com a empresa e com a
        forma de interação que você tem conosco.
        <br />
        <br />
        Entre os principais propósitos para os quais coletamos, processamos ou
        de outros modos tratamos seus dados pessoais estão os seguintes:
        <br />
        <br />
        a) Para cadastro de usuários. Trataremos os seus dados pessoais que
        forem necessários para realizar seu cadastro, a fim de contatar clientes
        e possíveis novos clientes, contatar fornecedores interessados em
        trabalhar conosco, avaliar candidatos inscritos para as vagas
        disponíveis na área de “Trabalhe com a gente” etc.;
        <br />
        <br />
        b) Para promoção das nossas atividades. Trataremos seus dados pessoais
        para lhe enviar nosso newsletter e informativos sobre nossos serviços,
        produtos, pesquisas, estudos, notícias ou qualquer outro conteúdo ou
        material que produzimos, no sentido de promover as nossas atividades;
        <br />
        <br />
        c) Para contato com clientes e possíveis novos clientes. Caso você tenha
        preenchido o formulário no website para entrar em contato com a IVI
        Energia sobre os temas de Responsabilidade Social, Editais, Recursos
        Humanos, Comunicação, Projetos especiais e outras solicitações,
        trataremos seus dados pessoais para entrar em contato com você, atender
        suas solicitações e compartilhar informações;
        <br />
        <br />
        d) Para responder suas dúvidas, elogios, reclamações, sugestões e nos
        comunicarmos com você. Quando você entra em contato conosco, seja por
        meio de nosso website, por telefone, por e-mail, whatsapp business ou
        por qualquer outro meio de comunicação, trataremos seus dados pessoais
        para podermos nos comunicar com você, respondendo às suas dúvidas,
        elogios, reclamações ou sugestões, bem como a qualquer mensagem que você
        nos tenha enviado, inclusive para a comunicação de problemas técnicos
        com a utilização de nosso website.
        <br />
        <br />
        e) Para marketing, envio de informações sobre nossos produtos ou
        serviços. Caso você já tenha um relacionamento comercial ou já tenha
        interagido com a IVI Energia anteriormente, ou tenha se cadastrado para
        o recebimento de materiais e informativos da IVI Energia por meio do
        website, poderemos tratar seus dados pessoais para marketing e/ou para o
        envio informações sobre os produtos ou serviços fornecidos pela IVI
        Energia.
        <br />
        <br />
        f) Para conduzir pesquisas de satisfação. Poderemos tratar seus dados
        pessoais quando realizarmos pesquisas de satisfação com nossos clientes,
        para identificar se os produtos ou serviços que ofertamos estão sendo
        recebidos positivamente e o que pode ser alterado para que a usabilidade
        e operabilidade sejam melhores.
        <br />
        <br />
        g) Para recrutar novos colaboradores. Quando você nos envia seu
        currículo por e-mail, redes sociais, pela área “Trabalhe com a gente”,
        entre outros, quando recebemos seu currículo de terceiros ou quando
        estamos com um processo seletivo em aberto e buscamos profissionais no
        mercado, trataremos seus dados pessoais para a finalidade de recrutar
        novos colaboradores, entrando em contato com eles e permitindo que
        participem de todas as etapas dos respectivos processos seletivos, como
        entrevistas, dinâmicas de grupos, testes práticos ou teóricos, entre
        outras atividades inerentes ao processo de recrutamento, para avaliação
        da compatibilidade do seu perfil profissional com as característica da
        vaga em aberto.
        <br />
        <br />
        h) Para analisar a utilização de nosso website. O acesso ao website
        poderá implicar na instalação ou pedido de autorização para instalação
        de cookies no dispositivo utilizado para o acesso, os quais têm por
        finalidade a coleta de informações relacionadas à atividade de navegação
        e que poderão servir para o aperfeiçoamento do funcionamento do site e
        suas ferramentas. Os cookies também poderão servir para a coleta e o
        armazenamento de informações sobre hábitos de visitas realizadas por
        cada usuário e suas preferências, com o intuito de possibilitar uma
        melhor experiência para o usuário, adotar melhorias no site e no
        conteúdo fornecido aos visitantes. Para maiores informações, favor
        acessar nosso Aviso de Cookies que aparece na janela automática ao
        acessar nosso website.
        <br />
        <br />
        i) Para garantir a segurança das nossas plataformas. Trataremos os seus
        dados pessoais para garantir a segurança do website, avaliando padrões
        não usuais em relação à sua utilização, requisições volumosas de acesso
        inesperado, tentativas de acesso com logins e/ou senhas inválidas, entre
        outras ações que podem indicar um ataque cibernético ou contra a
        confidencialidade, integridade ou disponibilidade de nossos sistemas.
        <br />
        <br />
        j) Para investigar eventuais denúncias. Quando você iniciar ou estiver
        envolvido em alguma denúncia realizada através do nosso “Canal de
        Confidencialidade”, ora disponibilizado no nosso website, em sobre
        condutas não condizentes como nossos valores empresariais ou que violem
        a legislação vigente, poderemos tratar seus dados pessoais para
        investigar as denúncias realizadas e, caso você tenha sido o denunciante
        e opte por revelar a sua identidade, reportar a você o desdobramento do
        processo relativo às denúncias.
        <br />
        <br />
        k) Para receber indicações de terceiros. Trataremos seus dados pessoais
        quando recebermos indicações de terceiros sobre o seu potencial
        interesse, ou o potencial interesse da sua organização, na contratação
        de nossos produtos ou serviços, permitindo o nosso posterior contato com
        você para apresentação de nossas soluções.
        <br />
        <br />
        l) Realização de reuniões, videoconferências ou conferências telefônicas
        . Trataremos seus dados pessoais para a realização de reuniões,
        videoconferências ou conferências telefônicas, incluindo, dependendo do
        meio utilizado, sua imagem e voz, além de dados de identificação e de
        correspondência eletrônica, para envio de convites e links para acesso
        às reuniões.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        V. Com quem a IVI Energia poderá compartilhar seus dados pessoais?
      </h1>

      <p className="paragra-container-modal-terms">
        Para desenvolver as nossas atividades, a IVI Energia poderá operar em
        parceria com outras organizações que fazem parte do mesmo grupo
        econômico (“Afiliadas”), fornecedores e demais parceiros de negócios e
        operacionais. Assim, em certos casos, é necessário compartilhar Dados
        Pessoais que são confiados com alguns parceiros, fornecedores e
        Afiliadas, bem como com autoridades públicas para cumprir exigências
        legais, conforme indicado abaixo:
        <br />
        <br />
        a) Fornecedores e Parceiros. Para viabilizar nossa operação e nossos
        serviços, a IVI Energia poderá buscar apoio de fornecedores, parceiros e
        Afiliadas. E, quando verificada a real necessidade, a IVI Energia poderá
        compartilhar os Dados Pessoais coletados dos usuários com estes
        parceiros, fornecedores e Afiliadas.
        <br />
        <br />
        b) Para cumprir com obrigações legais, regulatórias ou ordens judiciais.
        A IVI Energia tem como prioridade o cumprimento da lei. Logo, quando
        houver alguma obrigação legal ou regulatória, exigência de alguma
        autoridade judicial ou policial com a devida competência, que determine
        o fornecimento de Dados Pessoais, a IVI Energia será obrigada a cumprir
        e cumprirá. Em outras palavras, quando precisarmos cumprir com
        obrigações legais, regulatórias ou ordens judiciais, trataremos seus
        dados pessoais que sejam necessários para tais fins, inclusive mediante
        o compartilhamento desses dados com órgãos públicos e com o Poder
        Judiciário. Em caso de ordem abusiva ou excessiva a IVI Energia
        defenderá o direito à privacidade e à proteção de Dados Pessoais dos
        seus usuários.
        <br />
        <br />
        c) Para nos defender em processos judiciais, administrativos ou
        arbitrais. Quando for necessário, trataremos seus dados pessoais para
        defender nossos interesses em processos judiciais, administrativos ou
        arbitrais movidos por você ou por terceiros contra nós, ou movidos por
        nós contra você ou contra terceiros.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        VI. Transferência Internacional de Dados Pessoais
      </h1>

      <p className="paragra-container-modal-terms">
        Tendo em vista a possibilidade de operação em diversos países e a
        possibilidade de sistemas de computador em sede global, incluindo
        serviços de armazenamento de dados em nuvem, seus dados pessoais poderão
        ser transferidos internacionalmente, nos termos da legislação aplicável.
        <br />
        <br />
        Nem sempre essas transferências serão realizadas para países que possuam
        o mesmo nível de proteção de dados conferido pelas leis brasileiras.
        <br />
        <br />
        Implementaremos as salvaguardas necessárias para garantir que seus dados
        pessoais recebam um nível adequado de proteção, conforme exigido pela
        lei aplicável, e para garantir que tais transferências estejam em
        conformidade com esta Política. Nesses casos, realizaremos
        transferências internacionais somente para empresas que comprovarem que
        estão em conformidade com os requisitos comparativamente.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        VII. Dados Pessoais de Crianças e Adolescentes
      </h1>
      <p className="paragra-container-modal-terms">
        O website não é destinado para menores de 18 (dezoito) anos de idade e
        não coletamos voluntariamente dados pessoais de crianças e adolescentes.
        Recomendamos que o uso do website por menores seja sempre fiscalizado
        por seus pais e/ou responsáveis legais. Se tomarmos conhecimento de que
        coletamos dados pessoais de crianças e adolescentes, tomaremos as
        medidas adequadas para excluir essas informações dos nossos registros e
        banco de dados. Caso o usuário seja menor de 18 (dezoito) anos e deseje
        utilizar o website, será necessário o consentimento dos respectivos pais
        e representantes legais para o tratamento dos dados pessoais da criança
        ou adolescente.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        VIII. Tempo de Armazenamento dos Dados Pessoais
      </h1>
      <p className="paragra-container-modal-terms">
        Os dados pessoais são armazenados, segundo os seguintes parâmetros:
        <br />
        <br />
        1. Pelo tempo exigido por lei, respeitando-se os prazos prescricionais e
        as obrigações legais ou regulatórias;
        <br />
        <br />
        2. Pelo período que for necessário para alcançar as finalidades
        específicas de cada tratamento, com a retenção dos dados pela menor
        duração possível;
        <br />
        <br />
        3. Pelo tempo necessário para resguardar o exercício regular de direitos
        da IVI Energia em processo ou procedimento judicial, administrativo ou
        arbitral.
        <br />
        <br />
        Nesse sentido, os prazos de retenção de cada categoria de dados pessoais
        são definidos pela IVI Energia de acordo com as características
        específicas de cada tratamento, incluindo a natureza dos dados, as
        finalidades do tratamento, as obrigações legais ou regulatórias
        existentes, entre outros critérios relevantes para essa definição.
        <br />
        <br />
        Para obter mais informações sobre períodos de retenção específicos, por
        favor entre em contato com nosso Encarregado de Proteção de Dados
        descrito no item abaixo.
        <br />
        <br />
      </p>

      <h1 className="title-container-modal-terms">
        IX. Segurança a Informação
      </h1>
      <p className="paragra-container-modal-terms">
        Os dados pessoais que você nos fornece por meio do site são coletados,
        processados e protegidos em conformidade com padrões de segurança e
        confidencialidade, e utilizados apenas para as finalidades para os quais
        foram coletados, conforme descrito nesta Política. A IVI Energia adota
        diferentes medidas de segurança tecnológica e de processos para proteger
        os seus dados pessoais contra acessos não autorizados ou situações
        ilícitas ou acidentais de destruição, perda, uso indevido, alteração,
        destruição ou qualquer outra forma de tratamento inadequado. Seus dados
        são armazenados em um ambiente operacional seguro, que não é acessível
        ao público.
        <br />
        <br />
        Entre as medidas que adotamos para proteger seus dados pessoais estão:
        controles de acesso, monitoramento de acessos às redes locais e das
        conexões remotas via VPN, autenticação de usuários, antivírus, firewall,
        manutenção de cópias de segurança, realização periódica de testes e
        varreduras nos sistemas, dentre outras.
        <br />
        <br />
        Mantemos salvaguardas administrativas, técnicas e físicas para apoiar a
        proteção das informações pessoais que você compartilha conosco. Estas
        ações estão associadas ao esforço para prevenção a incidentes de
        segurança, mas sabemos que não é possível garantir a segurança absoluta
        dos seus dados, especialmente contra-ataques perpetrados por pessoas
        mal-intencionadas que podem ser altamente sofisticados e inovadores, com
        técnicas e métodos desconhecidos até então, mesmo pelas melhores
        ferramentas de segurança da informação.
        <br />
        <br />
        A segurança dos seus dados também depende da adoção de medidas razoáveis
        por você no uso de seus dispositivos e softwares. Caso você identifique
        ou tome conhecimento de algo que possa comprometer a segurança dos seus
        dados pessoais, ou de alguma possível vulnerabilidade em nosso site ou
        sistemas, por favor entre em contato conosco.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">X. Website de Terceiros</h1>
      <p className="paragra-container-modal-terms">
        Nosso website pode disponibilizar links para websites e plataformas de
        terceiros. Esses websites, por sua vez, possuem suas próprias políticas
        de privacidade, que podem não ser compatíveis com esta Política.
        Recomendamos que você consulte as respectivas políticas de privacidade
        desses terceiros para se informar sobre as práticas de proteção de dados
        pessoais adotadas por eles.
        <br />
        <br />
        A IVI Energia não se responsabiliza pela regularidade das práticas de
        proteção de dados dos websites de terceiros, nem pelo seu conteúdo, que
        não é de qualquer forma validado, ratificado ou subscrito pela IVI
        Energia.
        <br />
        <br />
      </p>
      <h1 className="title-container-modal-terms">
        XI. Como entrar em contato conosco
      </h1>
      <p className="paragra-container-modal-terms">
        A– LGPD garante aos titulares direitos relacionados aos seus Dados
        Pessoais e, para solicitar o exercício de qualquer um desses direitos
        garantidos pela lei é só entrar em contato conosco, e fale com o nosso
        Encarregado de Proteção de Dados, também conhecido como DPO (Data
        Protection Officer), através do e-mail: juridico@ivienergia.com
        <br />
        <br />
        Quando do envio do e-mail para contato, a depender do caso, a IVI
        Energia poderá pedir informações (e até mesmo documentos) pertinentes
        que confirmem/comprovem se o requisitante é o próprio titular dos dados
        ou seu representante legal, como forma de garantir a proteção à
        privacidade dos titulares.
        <br />
        <br />
        A IVI Energia se compromete em atender às solicitações dos titulares
        dentro do prazo razoável e em conformidade com a legislação aplicável.
        <br />
        <br />
        O canal disponibilizado poderá também ser utilizado para tirar dúvidas,
        fazer comentários e sugestões a respeito a esta Política de Privacidade.
        <br />
        <br />
        Em caso de identificação ou de conhecimento de qualquer fator que
        comprometa a segurança dos Dados tratados pelo website da IVI Energia,
        por favor entre em contato com o nosso Encarregado de Proteção de Dados,
        através do contato acima citado.
        <br />
        <br />
        <u>
          Quando não tivermos base legal prevista na LGPD que permita o
          tratamento de seus dados pessoais, poderemos tratar seus dados
          pessoais com base no seu consentimento, livre, inequívoco e informado,
          que poderá ser revogado a qualquer momento, de forma gratuita e fácil.
        </u>
        <br />
        <br />
        Nós nos certificaremos de que você seja informado de maneira
        transparente e, quando necessário, outras ações sejam tomadas para
        garantir a legalidade desse tratamento. Para maiores detalhes sobre a
        base legal utilizada em um tratamento de dados pessoais específico
        realizado por nós, entre em contato com nosso Encarregado, pelo e-mail:
        juridico@ivienergia.com
        <br />
        <br />
        A IVI Energia estará constantemente buscando melhorar os serviços
        prestados e a forma como realiza as suas operações. Por isso, esta
        Política de Privacidade pode passar por atualizações para refletir
        quaisquer melhorias realizadas. Por conta disso, a IVI Energia recomenda
        que você visite periodicamente esta página para que se mantenha
        atualizado.
        <br />
        <br />
      </p>
    </div>
  );
};
